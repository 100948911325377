<template>
  <div>
    <b-container class="mt-5">
      <!-- Introduction to Medical Tourism Section -->
      <b-row class="mt-3">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            MedAsk's Role
          </h3>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            Healthcare market in Pakistan provides an opportunity for MedAsk as
            a potential competitive player in medical tourism industry. MedAsk
            has access to the state-of-the-art medical facilities through
            established relationships with hospitals and healthcare
            professionals including a network of skilled doctors and specialists
            capable of delivering high-quality medical services to foreigners
            and overseas patients
          </p>
          <p
            class="text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            MedAsk through its platform expands service offerings by utilizing
            partnerships and collaborations with connected industries through
            associated companies and business units. Collaborations with
            hospitals, institutes, travel agencies, and tourism organizations
            enhance MedAsk's competitiveness in the medical tourism market,
            creating synergies and maximizing opportunities.
          </p>
        </b-col>
      </b-row>

      <!-- Scope and Opportunities Section -->
      <!-- <b-row class="mt-5">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            Scope and <span class="text-colorGreen"> Opportunities </span>
          </h3>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify"
          >
            The scope of medical tourism extends beyond traditional healthcare
            services and encompasses a wide range of medical specialties,
            including elective procedures, specialized surgeries, cosmetic
            treatments, and wellness services. Opportunities exist for
            healthcare providers, hospitals, clinics, and tourism organizations
            to capitalize on the growing demand for medical tourism services and
            to develop innovative strategies to attract and serve foreigners and
            overseas patients.
          </p>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            Pakistan is uniquely positioned to offer a wide range of specialized
            medical services and advanced treatments. Some key features which
            make Pakistan a prime location to attract foreigners and overseas
            patients include:
          </p>
        </b-col>
      </b-row> -->
      <!-- <b-container class="mt-5 mb-5">
        <b-row class="mt-3">
          <b-col md="12">
            <h3 class="text-colorBlue font-weight-bolder text-center">
              Dr Mohsin <span class="">Muhammad Qureshi</span>
            </h3>
            <h4 class="text-colorBlue font-weight-bolder text-center">
              Managing Director Medical – Fauji Foundation<br />
            </h4>

            <p
              class="pt-2 text-colorText text-left font-weight-bold"
              style="text-align: justify; text-justify: inter-word"
            >
              As we embark on a new journey with the launch of MedAsk Tours, I
              extend my sincere appreciation for your dedication and commitment
              to this important initiative. Your enthusiasm and expertise will
              play a crucial role in shaping the success of this venture. I
              emphasize the importance of meticulous planning, strategic vision,
              and collaboration as we lay the foundation for Medical Tourism.
              Let us work together to ensure that our strategies align with our
              mission and that we are well-prepared to deliver exceptional
              experiences to our future patients. Together, we are poised to
              make a significant impact in the healthcare industry, and I look
              forward to our collective efforts leading to a successful launch
              and long-term success.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col md="12">
            <h3 class="text-colorBlue font-weight-bolder text-center">
              Muhammad Imtiaz <span class="">Khan</span>
            </h3>
            <h4 class="text-colorBlue font-weight-bolder text-center">
              Executive Director – MedAsk<br />
            </h4>
            <p
              class="pt-2 text-colorText text-left font-weight-bold"
              style="text-align: justify; text-justify: inter-word"
            >
              As we prepare to launch MedAsk Tours, I want to express my deep
              appreciation for your dedication and vision. Your leadership,
              combined with our unwavering commitment to excellence, will drive
              our mission to deliver unparalleled healthcare experiences.
              Innovation, integrity, and collaboration are the pillars of our
              success. Let's work together to leverage our unique talents and
              create a legacy of exceptional patient care. The future of
              healthcare delivery is in our hands. Together, we can shape it,
              make a positive impact on lives, and achieve a brighter future.
              Let's make MedAsk Tours a resounding success!
            </p>
          </b-col>
        </b-row>
      </b-container> -->
      <!-- Pakistan's Unique Features Section -->
      <!-- <b-row class="mt-5">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">Specialiized Medical  <span class=" text-colorGreen"> Services</span></h3>
          <p class="pt-2 text-colorText text-center font-weight-bold">
            Highly skilled healthcare professionals and state-of-the-art medical facilities equipped with advanced technology and infrastructure.
          </p>
        </b-col>
      </b-row> -->
      <!-- <b-row class="d-flex justify-content-center align-items-start">
        <b-col md="4" v-for="(feature, index) in pakistanFeatures" :key="index">
          <b-card class="text-center bg-colorBlue">
            <b-card-title class="font-weight-bolder text-colorWhite">
              {{ feature.title }}
            </b-card-title>
            <div>
              <b-img
                :src="feature.icon"
                fluid
                alt="Icon"
                class="mb-2 icon-size"
              ></b-img>
            </div>
            <div>
              <p class="card-text text-colorWhite">
                {{ feature.description }}
              </p>
            </div>
          </b-card>
        </b-col>
      </b-row> -->

      <!-- Pakistan's Tourism Potential Section -->
      <!-- <b-row class="mt-5">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            Pakistan’s Tourism <span class="text-colorGreen"> Potential</span>
          </h3>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            Pakistan's diverse geography and varied landscapes offer unique
            opportunities to client’s seeking treatment combined with tourism
            options.
          </p>
        </b-col>
       <b-col md="4" v-for="(potential, index) in tourismPotential" :key="index" class="mb-4">
          <b-card :title="potential.title" class="text-center">
            <b-img :src="potential.icon" fluid alt="Icon" class="mb-2"></b-img>
            <p class="card-text text-colorText">
              {{ potential.description }}
            </p>
          </b-card>
        </b-col> 
        <ul v-for="(potential, index) in tourismPotential" :key="index">
          <li>
            <h4 class="text-colorGreen font-weight-bolder">
              {{ potential.title }}
            </h4>
          </li>
          <p>{{ potential.description }}</p>
        </ul>
        <div class="text-center w-100">
          <b-img
            src="@/assets/images/aboutUs/aboutUs1.png"
            class="img-fluid rounded w-50"
          />
        </div>
      </b-row> -->

      <!-- MedAsk's Role in Pakistan's Healthcare Market Section -->
      <!-- <b-row class="mt-5">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            MedAsk's Role in
            <span class="text-colorGreen"> Pakistan's Healthcare Market</span>
          </h3>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            The healthcare market in Pakistan provides an opportunity for MedAsk
            as a potential competitive player in the medical tourism industry.
            MedAsk has access to state-of-the-art medical facilities through
            established relationships with hospitals and healthcare
            professionals including a network of skilled doctors and specialists
            capable of delivering high-quality medical services to foreigners
            and overseas patients. Moreover, MedAsk can leverage the
            partnerships and collaborations with connected industries through
            associated companies and business units to expand its service
            offerings and enhance its competitiveness in the medical tourism
            market. Collaborations with hospitals, clinics, travel agencies, and
            tourism organizations can help create synergies and maximize
            opportunities.
          </p>
        </b-col>
        <b-col md="6"> </b-col>
      </b-row> -->

      <!-- Vision, Mission, Values -->
      <b-col md="12"> </b-col>
      <b-row class="mt-5 match-height">
        <b-col md="4" v-for="(vmvo, index) in vmvos" :key="index" class="">
          <b-card class="text-center bg-colorBlue">
            <b-card-title class="font-weight-bolder text-colorWhite">
              {{ vmvo.title }}
            </b-card-title>
            <b-img
              :src="vmvo.icon"
              fluid
              alt="Icon"
              class="mb-2 icon-size"
            ></b-img>
            <p class="card-text text-colorWhite">
              {{ vmvo.description }}
            </p>
          </b-card>
        </b-col>
        <p
          class="pt-1 px-1 text-colorText text-left font-weight-bold"
          style="text-align: justify; text-justify: inter-word"
        >
          MedAsk Tours is guided by core values which form the basis of
          decisions, actions, and behaviors, ensuring the highest standards of
          quality, professionalism, and performance. With empathy, honesty, and
          transparency, we foster teamwork, creativity, and continuous
          improvement, prioritizing the safety and well-being of all involved.
          Upholding respect, dignity, and fairness, we embrace diversity,
          inclusion, and cultural sensitivity in all interactions and
          operations.
        </p>
      </b-row>
      <b-row class="mt-1">
        <b-col md="12">
          <p
            class="text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            MedAsk Tours is guided by core values which form basis of decisions,
            actions, and behavior, ensuring the highest standards of quality,
            professionalism, and performance. With empathy, honesty, and
            transparency, we foster teamwork, creativity, and continuous
            improvement, prioritizing the safety and well-being of all involved.
            Upholding respect, dignity, and fairness, we embrace diversity,
            inclusion, and cultural sensitivity in all interactions and
            operations.
          </p>
          <p
            class="text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            The Executive Director (MedAsk) shall provide strategic leadership
            and governance to MedAsk Tours alongside other operations of MedAsk
            (Private) Limited supported by functional departments. The ED Office
            is responsible for overseeing the strategic direction and overall
            assessment of MedAsk Tours providing advisory support and strategic
            guidance while collaborating closely with the Director Medical
            Tourism.
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            Message by: Dr Mohsin Muhammad Qureshi
          </h3>
          <h4 class="text-colorBlue font-weight-bolder text-center">
            Managing Director
          </h4>

          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            As we embark on a new journey with the launch of MedAsk Tours, I
            extend my sincere appreciation for your dedication and commitment to
            this important initiative. Your enthusiasm and expertise will play a
            crucial role in shaping the success of this venture. I emphasize the
            importance of meticulous planning, strategic vision, and
            collaboration as we lay the foundation for Medical Tourism. Let us
            work together to ensure that our strategies align with our mission
            and that we are well-prepared to deliver exceptional experiences to
            our future patients. Together, we are poised to make a significant
            impact in the healthcare industry, and I look forward to our
            collective efforts leading to a successful launch and long-term
            success.
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            Message by: Muhammad Imtiaz Khan
          </h3>
          <h4 class="text-colorBlue font-weight-bolder text-center">
            Executive Director – MedAsk
          </h4>

          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            As we prepare to launch MedAsk Tours, I want to express my deep
            appreciation for your dedication and vision. Your leadership,
            combined with our unwavering commitment to excellence, will drive
            our mission to deliver unparalleled healthcare experiences.
            Innovation, integrity, and collaboration are the pillars of our
            success. Let's work together to leverage our unique talents and
            create a legacy of exceptional patient care. The future of
            healthcare delivery is in our hands. Together, we can shape it, make
            a positive impact on lives, and achieve a brighter future. Let's
            make MedAsk Tours a resounding success!
          </p>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            Professionals committed to delivering world-class medical services,
            seamless coordination, and personalized attention. Our comprehensive
            approach to medical tourism underscores our dedication to quality,
            safety, and patient satisfaction. We eagerly welcome patients from
            around the world and are proud to offer them supreme healthcare
            services along with our renowned hospitality.
          </p>
        </b-col>
      </b-row>
      <b-row style="width: 100%" class="text-center">
        <b-col md="12">
          <b-img
            src="@/assets/images/aboutUs/1.webp"
            alt="Icon"
            class="mb-2"
            style="width: 100%"
          ></b-img>
        </b-col>
      </b-row>

      <!-- Objectives Section -->
      <!-- <b-row class="mt-5">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            Objectives
          </h3>
        </b-col>
        <b-col md="4" v-for="(potential, index) in tourismPotential" :key="index" class="mb-4">
          <b-card :title="potential.title" class="text-center">
            <b-img :src="potential.icon" fluid alt="Icon" class="mb-2"></b-img>
            <p class="card-text text-colorText">
              {{ potential.description }}
            </p>
          </b-card>
        </b-col>
        <ul v-for="(objective, index) in Objectives" :key="index">
          <li>
            <h4 class="text-colorGreen font-weight-bolder">
              {{ objective.title }}
            </h4>
          </li>
          <p>{{ objective.description }}</p>
        </ul>
        <div class="text-center w-100">
          <b-img
            src="@/assets/images/aboutUs/Objectives.png"
            class="img-fluid rounded w-50"
          />
        </div>
      </b-row> -->

      <!-- Organizational Structure and Roles Section -->
      <!-- <b-row class="mt-5">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            Organizational <span class="text-colorGreen"> Structure </span>
          </h3>
          <div class="text-center w-100">
            <b-img
              src="@/assets/images/aboutUs/OrgainzationStructure.png"
              class="img-fluid rounded w-50"
            />
          </div>
        </b-col>
      </b-row> -->
      <!-- <b-row class="mt-5">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center pb-4">
            Roles and <span class="text-colorGreen"> Responsibilities</span>
          </h3>
        </b-col>
        <b-col
          md="6"
          v-for="(department, index) in departments"
          :key="index"
          class="mb-4"
        >
          <h5 class="text-colorGreen font-weight-bolder">
            {{ department.title }}
          </h5>
          <p class="pt-2 text-colorText font-weight-bold">
            {{ department.description }}
          </p>
          <ul class="text-colorText">
            <h6 class="font-weight-bolder">Key Responsibilities</h6>
            <li v-for="(role, idx) in department.roles" :key="idx">
              {{ role }}
            </li>
          </ul>
        </b-col>
      </b-row> -->
      <!-- Coordination with Government Ministries -->
      <!-- <b-row class="mt-5">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            Coordination with
            <span class="text-colorGreen"> Government Ministries</span>
          </h3>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            The MedAsk Tours team, together with these ministries, form a
            cohesive working group dedicated to advancing the objectives of
            MedAsk Tours, ensuring collaboration, coordination, and synergy
            across various sectors to promote Pakistan and MedAsk Tours as a
            premier destination for medical tourism.
          </p>
        </b-col>
        <b-col md="4" v-for="(potential, index) in tourismPotential" :key="index" class="mb-4">
          <b-card :title="potential.title" class="text-center">
            <b-img :src="potential.icon" fluid alt="Icon" class="mb-2"></b-img>
            <p class="card-text text-colorText">
              {{ potential.description }}
            </p>
          </b-card>
        </b-col>
        <ul v-for="(ministry, index) in gov_ministries" :key="index">
          <li>
            <h4 class="text-colorGreen font-weight-bolder">
              {{ ministry.title }}
            </h4>
          </li>
          <p>{{ ministry.description }}</p>
        </ul>
        <div class="text-center w-100">  
          <b-img
          src="@/assets/images/aboutUs/aboutUs1.png"
          class="img-fluid rounded w-50 "
        /></div>
      </b-row> -->
      <!-- . Client Journey Section -->
      <!-- <b-row class="mt-5">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            Client <span class="text-colorGreen"> Journey </span>
          </h3>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            The client journey within MedAsk Tours includes the complete process
            through which a patient will go through; starting from the first
            contact and pre-travel preparations to departure back home and
            post-treatment care. A dedicated team committed to providing
            personalized support and care shall remain available to ensure a
            seamless and satisfying experience for all clients.
          </p>
        </b-col>
        <b-col
          md="6"
          v-for="(journey, index) in client_journies"
          :key="index"
          class="mb-4"
        >
          <h5 class="text-colorGreen font-weight-bolder">
            {{ journey.title }}
          </h5>
          <ul class="text-colorText">
            <li v-for="(role, idx) in journey.roles" :key="idx">{{ role }}</li>
          </ul>
        </b-col>
        <p
          class="pt-2 text-colorText text-left font-weight-bold"
          style="text-align: justify; text-justify: inter-word"
        >
          The client journey at MedAsk Tours would reflect unwavering dedication
          to excellence, compassion, and client-centered care, committed to
          exceeding clients' expectations at every stage of their medical
          journey and striving to make their experience with MedAsk Tours as
          comfortable, seamless, and rewarding as possible.
        </p>
        <div class="text-center w-100">
          <b-img
            src="@/assets/images/aboutUs/boardingprocess.png"
            class="img-fluid rounded w-50"
          />
        </div>
      </b-row> -->

      <!-- Billing Process Section -->
      <!-- <b-row class="mt-5">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            Billing <span class="text-colorGreen"> Process</span>
          </h3>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            The billing begins with the registration and verification of
            clients, where essential information is collected such as personal
            details, medical history, desired treatment etc. After liaising with
            healthcare providers to estimate the cost of treatments, the client
            is provided with transparent cost estimates, including itemized
            breakdowns of fees and charges. After obtaining consent of the
            client, a contract will be signed and advance payment will be
            received from the client before departure from his home country.
          </p>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            Once the client arrives in Pakistan, the remaining payment is
            received and treatment is initiated. After finalization of treatment
            and patient discharge, the final bill is calculated and invoiced to
            the client based on which additional payment or refund (if any) is
            obtained or processed.
          </p>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            Payment collection is facilitated through online payments or wire
            transfers when the client is in his home country. Additionally, once
            he arrives in Pakistan, remaining payment can also be processed
            through POS terminal or cash.
          </p>
          <div class="text-center w-100">
            <b-img
              src="@/assets/images/aboutUs/billing.png"
              class="img-fluid rounded w-50"
            />
          </div>
        </b-col>
        <b-col md="6"> </b-col>
      </b-row> -->

      <!-- Documentation and Record-keeping Section -->
      <!-- <b-row class="mt-5">
        <b-col md="12">
          <h3 class="text-colorBlue font-weight-bolder text-center">
            Documentation and
            <span class="text-colorGreen"> Record-keeping </span>
          </h3>
          <p
            class="pt-2 text-colorText text-left font-weight-bold"
            style="text-align: justify; text-justify: inter-word"
          >
            Documentation and record-keeping are essential components of the
            MedAsk Tour’s operations, ensuring compliance with regulatory
            requirements, facilitating effective communication, and supporting
            quality client care.
          </p>
        </b-col>
        <b-col
          md="6"
          v-for="(documentation, index) in documentations"
          :key="index"
          class="mb-4"
        >
          <h5 class="text-colorGreen font-weight-bolder">
            {{ documentation.title }}
          </h5>
          <ul class="text-colorText">
            <li v-for="(role, idx) in documentation.roles" :key="idx">
              {{ role }}
            </li>
          </ul>
        </b-col>
      </b-row> -->
      <!-- <h3 class="text-colorBlue font-weight-bolder text-center">
        Insurance <span class="text-colorGreen"> Rates </span>
      </h3>
      <h4 class="text-colorBlue font-weight-bolder">
        Insurance Coverage for Clients
      </h4>
      <table class="coverage-table">
        <tr>
          <th>Key Highlights</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>Non-Primary Condition Hospitalization Coverage</td>
          <td>
            Covers medical expenses for unforeseen conditions unrelated to the
            primary treatment
          </td>
        </tr>
        <tr>
          <td>Accidental Emergency Insurance</td>
          <td>
            Covers emergency medical treatment for injuries from falls, road
            accidents, burns or other accidents
          </td>
        </tr>
        <tr>
          <td>Implant and Medical Device Insurance</td>
          <td>
            Covers repair or replacement of implants or medical devices in case
            of failure subsequent to procedure
          </td>
        </tr>
        <tr>
          <td>Complications Insurance</td>
          <td>
            Covers additional medical costs in case of unforeseen complications
            during treatment
          </td>
        </tr>
      </table>

      <table class="premium-table">
        <tr>
          <th>Insurance Premium</th>
          <th>Up-to 50 Years</th>
          <th>51 – 69 Years</th>
        </tr>
        <tr>
          <td>Hospitalization Benefit</td>
          <td class="text-center" colspan="2">PKR 3,000,000</td>
        </tr>
        <tr>
          <td>Room Entitlement</td>
          <td class="text-center" colspan="2">Private</td>
        </tr>
        <tr>
          <td>Post-hospitalization Expenses</td>
          <td class="text-center" colspan="2">15 Days</td>
        </tr>
        <tr>
          <td>Emergency Local Ambulance</td>
          <td class="text-center" colspan="2">PKR 50,000</td>
        </tr>
        <tr>
          <td>Premium</td>
          <td>PKR 90,000</td>
          <td>PKR 150,000</td>
        </tr>
      </table>
      <h4 class="text-colorBlue font-weight-bolder">
        Professional Indemnity Insurance
      </h4>
      <table class="indemnity-table">
        <tr>
          <th>Insurance Premium</th>
          <th>Professional Indemnity</th>
          <th>Doctors Negligence</th>
        </tr>
        <tr>
          <td>Limit per patient per ailment</td>
          <td class="text-center" colspan="2">USD 2,500</td>
        </tr>
        <tr>
          <td>Premium</td>
          <td>USD 750</td>
          <td>USD 1,250</td>
        </tr>
      </table>
      <h4 class="text-colorBlue font-weight-bolder">
        Insurance for Foreigners and Overseas (within Pakistan)
      </h4>
      <table class="foreigners-table">
        <tr>
          <th>Plan 1</th>
          <th>Currency</th>
          <th>Basic</th>
          <th>Standard</th>
          <th>Premium</th>
          <th>Bronze</th>
          <th>Silver</th>
          <th>Gold</th>
          <th>Diamond</th>
        </tr>
        <tr>
          <td>Hospitalization Limit</td>
          <td class="font-weight-bold reduce-space" rowspan="4">PKR</td>
          <td>150,000</td>
          <td>200,000</td>
          <td>300,000</td>
          <td>300,000</td>
          <td>400,000</td>
          <td>500,000</td>
          <td>600,000</td>
        </tr>
        <tr>
          <td>Maternity Sub Limit</td>
          <td>60,000</td>
          <td>80,000</td>
          <td>100,000</td>
          <td>80,000</td>
          <td>100,000</td>
          <td>150,000</td>
          <td>200,000</td>
        </tr>
        <tr>
          <td>Room Limit</td>
          <td>8,000</td>
          <td>10,000</td>
          <td>13,000</td>
          <td>10,000</td>
          <td>12,000</td>
          <td>13,000</td>
          <td>15,000</td>
        </tr>
        <tr>
          <td>Critical Illness (Head of Family)</td>
          <td>-</td>
          <td>-</td>
          <td>500,000</td>
          <td>700,000</td>
          <td>900,000</td>
          <td>1,000,000</td>
        </tr>
        <tr>
          <td>Annual Premium</td>
          <td>USD</td>
          <td>80</td>
          <td>105</td>
          <td>150</td>
          <td>190</td>
          <td>250</td>
          <td>335</td>
          <td>400</td>
        </tr>
      </table>

      <table class="foreigners-table">
        <tr>
          <th>Plan 2</th>
          <th>Currency</th>
          <th>Basic</th>
          <th>Standard</th>
          <th>Premium</th>
          <th>Bronze</th>
          <th>Silver</th>
          <th>Gold</th>
          <th>Diamond</th>
        </tr>
        <tr>
          <td>Hospitalization Limit</td>
          <td class="font-weight-bold reduce-space" rowspan="4">PKR</td>
          <td>200,000</td>
          <td>400,000</td>
          <td>700,000</td>
          <td>500,000</td>
          <td>700,000</td>
          <td>800,000</td>
          <td>1,000,000</td>
        </tr>
        <tr>
          <td>Maternity Sub Limit</td>
          <td>100,000</td>
          <td>150,000</td>
          <td>250,000</td>
          <td>150,000</td>
          <td>200,000</td>
          <td>250,000</td>
          <td>300,000</td>
        </tr>
        <tr>
          <td>Room Limit</td>
          <td>8,000</td>
          <td>10,000</td>
          <td>13,000</td>
          <td>10,000</td>
          <td>12,000</td>
          <td>13,000</td>
          <td>15,000</td>
        </tr>
        <tr>
          <td>Critical Illness (Head of Family)</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>700,000</td>
          <td>1,000,000</td>
          <td>1,500,000</td>
          <td>2,000,000</td>
        </tr>
        <tr>
          <td>Annual Premium</td>
          <td>USD</td>
          <td>115</td>
          <td>205</td>
          <td>250</td>
          <td>310</td>
          <td>430</td>
          <td>550</td>
          <td>650</td>
        </tr>
      </table> -->

      <!-- Contact Us Section -->
      <b-row
        style="margin-top: 9rem"
        class="mt-5 d-flex align-items-center justify-content-center"
      >
        <b-col md="5" class="p-5">
          <img
            src="@/assets/images/aboutUs/3.webp"
            class="img-fluid d-none d-md-flex mb-3 rounded"
            alt="Logo"
          />
        </b-col>
        <b-col md="7">
          <h3 class="font-weight-bolder text-colorBlue text-center">
            Embark on Your Medical Tourism Journey Today
          </h3>
          <p
            class="pt-2 font-weight-bold text-colorText text-left"
            style="text-align: justify; text-justify: inter-word"
          >
            Let MedAskTours be your guide to a transformative healthcare
            experience. Contact us today to discover how we can help you achieve
            your health goals while creating unforgettable travel memories.
          </p>
          <div class="text-center mt-4 mb-2 mb-md-0">
            <b-button
              rounded
              variant="colorGreen"
              size="lg"
              class="font-weight-bolder"
              :to="{ name: 'ContactUs' }"
              >Contact Us Now
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pakistanFeatures: [
        {
          icon: require("@/assets/images/icons/aboutUs/medical_services.svg"),
          title: "Specialized Medical Services",
          description:
            "Highly skilled healthcare professionals and state-of the-art medical facilities equipped with advanced technology and infrastructure. ",
        },
        {
          icon: require("@/assets/images/icons/aboutUs/healthcare_solutions.svg"),
          title: "Cost-Effective Healthcare Solutions",
          description:
            "Compared to Western countries and some other Asian nations, medical treatment costs in Pakistan are relatively lower,\
           making it an attractive destination for medical tourists seeking affordable healthcare solutions. ",
        },
        {
          icon: require("@/assets/images/icons/aboutUs/accessibility.svg"),
          title: "Accessibility and Convenience",
          description:
            "Pakistan's geographical location makes it easily accessible from various parts of the world, offering convenient travel options for medical tourists. ",
        },
        {
          icon: require("@/assets/images/icons/aboutUs/specialties.svg"),
          title: "Diverse Medical Specialties",
          description:
            "Pakistan boasts a diverse range of medical specialties, allowing medical tourists to access a wide array of treatments and procedures. ",
        },
        {
          icon: require("@/assets/images/icons/aboutUs/rehabilitation.svg"),
          title: "Wellness and Rehabilitation",
          description:
            "Pakistan's scenic landscapes provide an ideal environment for post-treatment recovery, rehabilitation, and wellness activities. \
          Patients can combine medical treatment with relaxation therapies and nature retreats to promote holistic healing and well-being.",
        },
        {
          icon: require("@/assets/images/icons/aboutUs/historical_attractions.svg"),
          title: "Cultural and Historical Attractions",
          description:
            "In addition to medical treatment, medical tourists visiting Pakistan can explore the country's rich cultural heritage and historical landmarks.",
        },
      ],
      tourismPotential: [
        {
          title: "Four Seasons Experience",
          description:
            "Pakistan experiences all four seasons, offering clients the opportunity to undergo treatment in a climate that suits their preferences and medical needs. Whether clients prefer the warmth \
          of summer or the coolness of winter, Pakistan provides options for treatment in any season. ",
        },
        {
          title: "Scenic Landscapes",
          description:
            "Pakistan boasts a diverse range of landscapes, including majestic mountains, serene rivers, lush farms, and picturesque coastal areas. Clients can choose\
           treatment locations based on their desired surroundings, whether it's the beauty of the northern mountains or the soothing ambiance of coastal resorts. ",
        },
        {
          title: "Mountainous Regions",
          description:
            "The northern regions of Pakistan are home to some of the world's highest mountain ranges, including the Himalayas, Karakoram, and Hindu Kush.\
           These areas not only offer breathtaking natural scenery but also provide opportunities for medical tourism in high-altitude resorts, which are known for \
           their therapeutic benefits. ",
        },
        {
          title: "Rivers and Valleys",
          description:
            "Pakistan's rivers, such as the Indus, Jhelum, and Chenab, flow through picturesque valleys, offering scenic views and recreational activities.\
           Clients can opt for tourism options located near these riverside destinations, where they can enjoy the tranquility of nature during their recovery process. ",
        },
        {
          title: "Coastal Areas",
          description:
            "Pakistan's coastline along the Arabian Sea provides opportunities for medical tourism in coastal cities such as Karachi and Gwadar. Clients can\
           combine their treatment with leisure activities and relaxation by the sea. ",
        },
        {
          title: "Urban Centers",
          description:
            " Pakistan's major cities offer modern healthcare facilities and infrastructure, making them ideal destinations for clients seeking advanced medical \
          treatments. Urban centers provide a wide range of healthcare services, accommodation options, and amenities to cater to the diverse needs of medical tourists. ",
        },
        {
          title: "Cultural and Heritage Sites",
          description:
            "In addition to its natural beauty, Pakistan is rich in cultural heritage, with historical landmarks, ancient ruins, and architectural marvels scattered throughout the country. \
          Clients can explore these cultural attractions during their stay, adding a cultural dimension to their medical tourism experience.",
        },
      ],
      vmvos: [
        {
          icon: require("@/assets/images/icons/aboutUs/vision.svg"),
          title: "Vision Statement",
          description:
            "Be a leading medical tourism services provider, providing highest quality care, compassion, and support, while delivering a culturally appropriate, affordable, seamless, and enjoyable experience",
        },
        {
          icon: require("@/assets/images/icons/aboutUs/mission.svg"),
          title: "Mission Statement",
          description:
            "Curate a seamless and unforgettable medical tourism experience, empowering individuals to access high quality, culturally apt and cost-effective healthcare with a touch of excellence, while ensuring tailor-made care and elevating every touch point with added value",
        },
        {
          icon: require("@/assets/images/icons/aboutUs/values.svg"),
          title: "Core Values",
          description:
            "Excellence, Compassion, Integrity, Collaboration, Innovation, Safety, Respect.",
        },
      ],
      Objectives: [
        {
          title: "Provide Quality Healthcare Services: ",
          description:
            "Our primary objective is to deliver superior healthcare services to foreigners and overseas patients, meeting their medical needs with compassion, professionalism, and expertise. ",
        },
        {
          title: "Enhance Patient Experience",
          description:
            "We aim to enhance the overall patient experience by offering personalized care, seamless coordination, and comprehensive support services before, during, and after their medical treatment",
        },
        {
          title: "Promote Medical Excellence",
          description:
            "We strive to promote medical excellence by collaborating with leading healthcare professionals and facilities, adopting best practices, and investing in continuous education and training.",
        },
        {
          title: "Drive Growth and Sustainability",
          description:
            "Our goal is to drive growth and sustainability in the medical tourism segment by expanding our service offerings, increasing market share, and maximizing operational efficiency.",
        },
        {
          title: "Ensure Compliance and Ethical Practices",
          description:
            "We are committed to upholding the highest standards of ethics, integrity, and compliance in all our interactions with patients, partners, and stakeholders, ensuring transparency and trust. ",
        },
        {
          title: "Foster Innovation and Adaptability",
          description:
            "We seek to foster a culture of innovation and adaptability within the organization, embracing new technologies, methodologies, and trends to stay ahead of the curve and meet evolving patient needs.",
        },
        {
          title: "Contribute to Economic Development",
          description:
            "We aim to contribute to the economic development of Pakistan by attracting foreigners and overseas patients, generating revenue, creating employment opportunities, and supporting local businesses and communities.",
        },
      ],
      departments: [
        {
          title: "Executive Director (MedAsk)",
          description:
            "The Executive Director (MedAsk) shall provide strategic leadership and governance to MedAsk Tours alongside other operations\
           of MedAsk (Private) Limited supported by functional departments. The ED Office is responsible for overseeing the strategic direction and \
            overall assessment of MedAsk Tours providing advisory support and strategic guidance while collaborating closely with the Director Medical Tourism.",
          roles: [
            "Provide strategic direction and leadership in consultation with Director Medical Tourism.",
            "Analyze key metrics and conduct overall assessment and performance evaluation of MedAsk Tours.",
            "Provide advisory support and guidance for business development opportunities and stakeholder engagement.",
            "Facilitate strategic partnerships, collaborations, and networking opportunities to support business.",
            "Oversee budgets, forecasts, and resource allocation plans to ensure optimum performance with cost-effectiveness.",
            "Monitor financial performance, analyze variances, and implement corrective actions to achieve budgetary targets.",
          ],
        },
        {
          title: "Director Medical Tourism",
          description:
            "The Director leads the MedAsk Tours project and coordinates activities across functional units.",
          roles: [
            "Develop and execute strategic plans, goals, and initiatives.",
            "Identify opportunities to drive business growth.",
            "Develop best practice policies and procedures.",
            "Supervise departmental staff and oversee their performance.",
            "Coordinate with other departments to ensure seamless operations.",
            "Manage relationships with external partners, including healthcare providers, regulatory bodies, industry stakeholders, and other allied service providers.",
            "Monitor departmental budgets and expenses.",
          ],
        },
        {
          title: "Coordination & Administration Department",
          description:
            "The Coordination & Administration department is responsible for managing administrative functions and coordinating patient services. ",
          roles: [
            "Coordinate patient appointments, travel arrangements, and accommodation.",
            "Supervise administrative staff and ensure smooth day-to-day operations.",
            "Maintain records and documentation related to patient services.",
            "Handle inquiries and provide assistance to patients and their families.",
            "Liaise with healthcare providers and facilitate communication between patients and medical staff.",
          ],
        },
        {
          title: "Medical Services Department",
          description:
            "The Medical Services department oversees medical operations and ensures the delivery of high-quality patient care.",
          roles: [
            "Coordinate medical appointments, consultations, and treatments for patients.",
            "Supervise medical staff and ensure adherence to clinical protocols and standards.",
            "Monitor patient outcomes and satisfaction levels.",
            "Implement quality improvement initiatives to enhance medical services.",
            "Collaborate with healthcare providers to optimize patient care pathways.",
            "Stay updated on medical advancements and best practices to inform management.",
          ],
        },
        {
          title: "Allied Services Department",
          description:
            "The Allied Services department oversees ancillary services and support functions within MedAsk Tours.",
          roles: [
            "Manage ancillary services such as transportation, accommodation, and translation services.",
            "Supervise staff responsible for providing support to patients and their families.",
            "Ensure the availability of necessary resources and facilities for patient care.",
            "Coordinate with external vendors and service providers to fulfill patient needs.",
            "Implement quality improvement initiatives to enhance the patient experience.",
          ],
        },
        {
          title: "Sales & Marketing Department",
          description:
            "The Sales & Marketing department is responsible for developing and implementing marketing strategies to promote medical tourism services and attract patients.",
          roles: [
            "Develop marketing plans and campaigns to generate leads and increase patient inquiries.",
            "Implement digital marketing strategies, including social media, email, and online advertising.",
            "Coordinate promotional events, conferences, and trade shows.",
            "Monitor market trends and competitor activities to identify opportunities for differentiation.",
            "Track and analyze marketing metrics to assess campaign effectiveness.",
            "Collaborate with internal teams and external partners to enhance brand visibility and reputation.",
          ],
        },
        {
          title: "Information Technology (IT) Department",
          description:
            "The IT department oversees technology infrastructure, systems, and support services.",
          roles: [
            "Manage IT systems, networks, and infrastructure to support operations.",
            "Implement and maintain software applications and databases.",
            "Provide technical support and troubleshooting for IT-related issues.",
            "Ensure data security and privacy compliance.",
            "Evaluate and recommend technology solutions to improve efficiency and effectiveness.",
            "Collaborate with external vendors and IT partners as needed.",
          ],
        },
        {
          title: "Finance Department",
          description:
            "The Finance department oversees financial planning, budgeting, and accounting functions.",
          roles: [
            "Develop and monitor budgets and financial plans.",
            "Ensure compliance with financial regulations and reporting requirements.",
            "Manage accounts payable, accounts receivable, and payroll processes.",
            "Conduct financial analysis and provide recommendations for cost optimization.",
            "Prepare financial reports and present financial performance to senior management.",
          ],
        },
      ],
      gov_ministries: [
        {
          title:
            "Ministry of National Health Services, Regulations and Coordination",
          description:
            "Charged with the development and regulation of healthcare services in Pakistan, this ministry plays a \
          central role in promoting medical tourism by facilitating access to quality healthcare services, ensuring regulatory compliance, and coordinating with healthcare providers. ",
        },
        {
          title: "Ministry of Tourism",
          description:
            "Tasked with promoting tourism and hospitality, the Ministry of \
                Tourism collaborates with the medical tourism initiative to showcase Pakistan's healthcare \
                facilities and tourist attractions to foreigners and overseas patients, thereby attracting clients to the country. ",
        },
        {
          title: "Ministry of Foreign Affairs",
          description:
            "The Ministry of Foreign Affairs handles diplomatic \
relations and international affairs. It supports the medical tourism initiative by liaising with  \
foreign governments, embassies, and diplomatic missions to promote Pakistan as a \
preferred destination for medical treatment ",
        },
        {
          title: "Ministry of Aviation",
          description:
            "Responsible for civil aviation and air transport, the Ministry of \
Aviation plays a vital role in facilitating the travel of clients to and from Pakistan. It ensures \
smooth operations at airports, coordinates air travel logistics, and promotes connectivity \
with international destinations. ",
        },
        {
          title: "Ministry of Overseas Pakistanis & Human Resource Development",
          description:
            ": This ministry focuses on the welfare and development of overseas Pakistanis, including those seeking \
medical treatment abroad. It collaborates with the medical tourism initiative to support \
overseas Pakistanis seeking healthcare services in their home country and to enhance the \
skills and capabilities of healthcare professionals involved in medical tourism. ",
        },
        {
          title: "Ministry of Defense",
          description:
            "The Ministry of Defense plays a pivotal role in facilitating MedAsk \
Tours to liaison and coordinate with other ministries. ",
        },
      ],
      client_journies: [
        {
          title: "Pre-Travel",
          roles: [
            "Provide guidance and support for clients via website or online meetings.",
            "Share information on available medical services and treatments.",
            "Priority assistance with scheduling appointments and consultations with panel doctors and specialists.",
            "Pre-travel medical assessments and consultations to determine suitability for treatment.",
            "Guidance on travel arrangements, including visa facilitation, air ticketing, and accommodation arrangements.",
            "Coordination of accommodation and logistical arrangements.",
          ],
        },
        {
          title: "Arrival and Reception",
          roles: [
            "Ensure a warm welcome and seamless arrival experience for clients.",
            "Airport pickup and transportation to accommodation or medical facility.",
            "Reception and orientation sessions to familiarize clients with the destination and facility.",
            "Introduction to medical staff and coordinators.",
            "Provision of welcome kits and essential information for clients' stay.",
          ],
        },
        {
          title: "Medical Consultation and Treatment",
          roles: [
            "Facilitate medical consultations and treatment procedures according to clients' needs.",
            "Coordination of appointments with specialist physicians and hospital.",
            "Comprehensive medical evaluations and diagnostic tests as required.",
            "Ensuring client comfort and support throughout the treatment process.",
          ],
        },
        {
          title: "Post Treatment Care",
          roles: [
            "Provide comprehensive post-treatment care and support to clients.",
            "Monitoring patient recovery and progress following treatment.",
            "Management of post-operative care and medication regimes.",
            "Follow-up consultations and assessments to track patient outcomes.",
            "Rehabilitation and physiotherapy services as necessary for optimal recovery.",
          ],
        },
        {
          title: "Discharge and Departure",
          roles: [
            "Ensure a smooth discharge process and departure for clients.",
            "Final medical assessments and clearance for discharge.",
            "Initiate tourism arrangements based on suitability, if opted by the client.",
            "Provision of discharge summaries, medical records, and prescriptions for continued care.",
            "Arrangements for transportation to the airport or onward destination.",
            "Follow-up communication with clients to ensure satisfaction and address any concerns.",
          ],
        },
      ],
      documentations: [
        {
          title: "Client Record Management",
          roles: [
            "Establishing standardized protocols for documenting client information, medical history, treatment plans, and clinical observations.",
            "Implementing electronic systems to centralize client records, improve accessibility, and enhance data security.",
            "Ensuring compliance with client confidentiality and privacy regulations by restricting access to sensitive information and maintaining strict confidentiality protocols.",
            "Conducting regular reviews of client records to ensure accuracy, completeness, and compliance with regulatory standards.",
          ],
        },
        {
          title: "Legal Documentation Requirements",
          roles: [
            "Maintaining comprehensive documentation of legal agreements, contracts, consent forms, and other legal documents related to client care, medical services, and business operations.",
            "Ensuring that all legal documentation is up-to-date, accurately drafted, and properly executed to mitigate legal risks and liabilities.",
            "Implementing document management systems to organize, store, and retrieve legal documents efficiently, reducing the risk of loss or misplacement.",
          ],
        },
        {
          title: "Data Security and Privacy Policies",
          roles: [
            "Development and enforcement of robust data security policies and procedures to safeguard client information from unauthorized access, disclosure, or misuse.",
            "Implementation of data encryption, multi-factor authentication, and regular updates of security software to protect against cyber threats and data breaches.",
            "Training of staff members on data security best practices to minimize the risk of security incidents.",
            "Conducting regular IT risk assessments and vulnerability scans to identify and address potential security gaps or weaknesses in data protection measures.",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.text-colorBlue {
  color: #007bff; /* Adjust color as needed */
}

.text-colorGreen {
  color: #28a745; /* Adjust color as needed */
}

.text-colorText {
  color: #343a40; /* Adjust color as needed */
}

.font-weight-bolder {
  font-weight: bolder;
}

.font-weight-normal {
  font-weight: normal;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

.icon-size {
  width: 150px; /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
}

.bg-colorBlue {
  background-color: blue;
}

.text-colorWhite {
  color: white;
}
</style>
